@import "../../styles/_variables.scss";

.background {
  width: 100%;
  background-color: #0c0c0c;
  padding: 128px 0 52px;
  color: #fff;

  .container {
    display: flex;
    max-width: $containerWidth;
    flex-wrap: wrap;

    width: 100%;
    margin: 0 auto;
    padding: 0 $containerPadding;

    .logo {
      max-width: 120px;
      height: 50px;
      flex-shrink: 0;
    }

    .column {
      display: flex;
      flex-direction: column;
      gap: 32px;
      flex-basis: 250px;
      padding-bottom: 32px;

      &:first-child {
        flex-basis: 500px;
      }

      &:not(:last-child) {
        padding-right: 64px;
      }
    }

    .socialMedia {
      display: flex;
      gap: 16px;
    }
  }

  .link {
    font-size: 16px;
    font-weight: 400;
    letter-spacing: -0.5px;
    text-decoration: none;
    color: #fff;
  }

  .bold {
    font-weight: 700;
  }
}
