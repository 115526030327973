@import "../../../../styles/_variables.scss";

.background {
  position: relative;

  .image {
    position: absolute;
    top: 0;
    right: 0;
    width: 100%;
    height: 100%;
    z-index: -1;
    background-color: var(--basic-black);

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }
}

.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 48px;

  padding: 80px $containerPadding;

  color: #fff;
  min-height: 583px;
  height: 100%;

  a {
    color: #fff;
    text-decoration: none;
  }

  .wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 12px;
    text-align: center;
    max-width: $containerWidth;

    h1 {
      font-size: 18px;
      font-weight: 700;
      line-height: 30px;
      letter-spacing: 0.18px;
      text-transform: uppercase;
    }

    h2 {
      font-size: 48px;
      font-weight: 600;
      line-height: 60px;
    }
  }
}
