@import "../../../../styles/_variables.scss";

.background {
  display: flex;
  background: #f8fafc;

  .container {
    display: flex;
    flex-direction: column;
    gap: 40px;
    flex-wrap: wrap;

    width: 100%;
    max-width: $containerWidth;
    padding: 48px $containerPadding;
    margin: 0 auto;
  }

  .title {
    color: var(--title-color);
    font-size: 40px;
    font-weight: 700;
    line-height: 60px;
    letter-spacing: -1.5px;
  }

  .card {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 42px;
    flex-wrap: wrap;
    flex-shrink: 0;

    padding: 64px 100px;

    border-radius: 12px;
    width: 100%;
    height: 100%;
    background-color: var(--basic-white);
    box-shadow: inset 0 -1px 1px var(--text-color-2);

    .image {
      position: relative;
      width: 200px;
      height: 200px;
      max-width: 100%;

      border-radius: 50%;
      overflow: hidden;
      flex-shrink: 0;
      background-color: var(--text-color-4, #455a64);

      img {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }

    .wrapper {
      display: flex;
      flex-direction: column;

      flex: 1;
      width: 500px;
      max-width: 100%;
      color: var(--text-color-4, #455a64);

      .icon {
        margin-bottom: 16px;
        width: 50px;
        height: 36px;
        path {
          fill: var(--text-color-6);
        }
      }

      .description {
        font-size: 16px;
        font-weight: 500;
        line-height: 24px;
        letter-spacing: -1px;
        margin-bottom: 34px;
      }

      .name {
        font-size: 12px;
        font-weight: 700;
        line-height: 16px;
        letter-spacing: -0.5px;
        margin-bottom: 4px;
      }

      .role {
        font-size: 10px;
        font-weight: 500;
        line-height: 16px;
      }
    }
  }
}

@media screen and (max-width: 500px) {
  .card {
    padding: 64px 32px !important;
  }
}
