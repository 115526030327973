* {
  box-sizing: border-box;
  padding: 0;
  margin: 0;
  font-family: "DM Sans", sans-serif;
}

button {
  border: none;
  outline: none;
  background: none;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
}

fieldset {
  border: none;
}

button:disabled {
  cursor: not-allowed;
}

body {
  font-family: "DM Sans", sans-serif;
  scroll-behavior: smooth;
}

*::selection {
  background: #d42087;
  color: #fff;
}

*:focus {
  outline: 2px solid #d31d89;
  border-radius: 8px;
}
