.container {
  display: flex;
  flex-direction: column;
  gap: 8px;
  width: 100%;
  max-width: 750px;
  margin: 0 auto;

  button,
  p {
    all: unset;
  }

  .item {
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 12px;

    width: 100%;
    padding: 16px 24px;

    border-radius: 8px;
    border: 1px solid #ce0c9a;
    background: #d31d89;

    transition: all 300ms ease-out;

    .trigger {
      display: flex;
      justify-content: space-between;
      align-items: center;

      width: 100%;

      color: #fff;

      font-size: 20px;
      font-weight: 700;
      letter-spacing: -1.5px;

      svg {
        cursor: pointer;
      }
    }

    .content {
      font-size: 16px;
      color: var(--text-color-4);
      font-weight: 400;
      line-height: 34px;
      overflow: hidden;

      &[data-state="open"] {
        animation: slideDown 300ms ease-out;
      }
      &[data-state="closed"] {
        color: #fff;
      }
    }

    &[data-state="open"] {
      background: #fff;

      .trigger {
        color: #d31d89;
        svg {
          transform: rotate(180deg);
          transition: transform 300ms;
          path {
            stroke: #ce0c9a;
          }
        }
      }
    }
  }
}

@keyframes slideDown {
  from {
    height: 0;
  }
  to {
    height: var(--radix-accordion-content-height);
  }
}

@keyframes slideUp {
  from {
    height: var(--radix-accordion-content-height);
  }
  to {
    height: 0;
  }
}
