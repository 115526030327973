@import "../../../../styles/_variables.scss";

.background {
  background: #f7f9fb;
}

.container {
  display: flex;
  flex-direction: column;
  gap: 48px;

  width: 100%;
  max-width: $containerWidth;
  padding: 100px $containerPadding 150px;
  margin: 0 auto;

  .notFound {
    font-size: 24px;
    text-align: center;
  }

  .title {
    color: var(--title-color);
    font-size: 40px;
    font-weight: 700;
    line-height: 60px;
    letter-spacing: -1.5px;
  }

  .jobs {
    display: flex;
    flex-direction: column;
    gap: 16px;
  }

  .card {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 16px;

    padding: 40px;
    width: 100%;
    background-color: var(--basic-white);
    border-radius: 10px;

    text-align: start;
    transition: all 0.2s ease;

    &:hover {
      box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.05);
      transform: translateY(-4px);
    }
  }

  .content {
    display: flex;
    flex-direction: column;
    gap: 18px;
  }

  .wrapper {
    display: flex;
    align-items: center;
    gap: 48px;
  }

  p {
    display: flex;
    align-items: center;
    gap: 4px;
  }

  .arrow {
    width: 24px;
    height: 24px;
  }
}

@media screen and (max-width: 768px) {
  .container {
    .wrapper {
      flex-direction: column;
      align-items: flex-start;
      gap: 12px;
    }
  }
}
