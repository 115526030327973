@import "../../../../styles/_variables.scss";

.background {
  color: var(--basic-white, #fff);
  background-color: #242222;
  scroll-margin-top: 96px;

  .container {
    width: 100%;
    max-width: $containerWidth;
    padding: 72px $containerPadding 120px;

    margin: 0 auto;

    display: flex;
    gap: 32px;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
  }

  .wrapper {
    display: flex;
    flex-direction: column;
    gap: 24px;
    width: 100%;
    max-width: 500px;
    margin: 0 auto;
  }

  .title {
    color: var(--basic-white, #fff);
    font-size: 40px;
    font-weight: 700;
    line-height: 120%;
  }
}
