.container {
  display: flex;
  flex-direction: column;
  gap: 24px;
  max-width: 500px;

  .title {
    color: #fff;
    font-size: 48px;
    font-weight: 700;
    line-height: 120%;
  }

  .subtitle {
    color: #e5e5e5;
    font-size: 20px;
    font-weight: 500;
    line-height: 150%;
  }

  .form {
    display: flex;
    flex-direction: column;
    gap: 16px;

    border-radius: 22px;
    background: #fff;
    flex-shrink: 0;
    padding: 32px;

    .input {
      border-radius: 8px;
      border: 1px solid #e5e5e5;
      padding: 24px 20px;
      font-size: 16px;
      font-weight: 400;
      letter-spacing: -0.5px;
      color: #242222;

      &::placeholder {
        color: var(--text-color-4);
      }

      ~ textarea {
        height: 140px;
      }
    }
  }
}
