@import "../../../../styles/_variables.scss";

.container {
  display: flex;
  flex-direction: column;
  gap: 24px;

  width: 100%;
  max-width: $containerWidth;
  padding: 100px $containerPadding;
  margin: 0 auto;

  .title {
    color: var(--title-color);
    font-size: 40px;
    font-weight: 700;
    line-height: 60px;
    letter-spacing: -1.5px;
  }

  .wrapper {
    max-width: 1000px;
    width: 100%;
    margin: 0 auto;
  }

  .description {
    color: var(--text-color-4);
    font-size: 20px;
    font-weight: 400;
    line-height: 34px;
    letter-spacing: 0.5px;
    margin-bottom: 48px;
  }

  .cards {
    display: flex;
    flex-wrap: wrap;
    gap: 24px;
    justify-content: center;
  }

  .card {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 12px;

    padding: 48px 24px;
    background: var(--basic-white, #fff);
    box-shadow: 0px 4px 16px rgba(0, 0, 0, 0.08);

    width: 100%;
    max-width: 300px;
    aspect-ratio: 1 / 1;

    transition: box-shadow 0.2s ease-in-out, transform 0.2s ease-in-out;

    &:hover {
      box-shadow: 0px 4px 16px rgba(0, 0, 0, 0.16);
      transform: translateY(-4px);
    }
  }

  .cardTitle {
    color: var(--title-color);
    text-align: center;
    font-size: 17px;
    font-weight: 500;
    line-height: 30px;
    letter-spacing: -0.5px;
  }

  .item {
    color: var(--text-color-4);
    font-size: 16px;
    font-weight: 400;
    line-height: 26px;
    letter-spacing: -0.5px;
    width: 100%;
  }
}
