@import "../../styles/_variables.scss";

$navbarHeight: 96px;

.background {
  display: flex;
  align-items: center;
  justify-content: center;

  position: sticky;
  top: 0;

  height: $navbarHeight;
  background-color: #ffffff;
  box-shadow: 0px 1px 8px rgba(0, 0, 0, 0.1);

  z-index: 99;

  ul {
    list-style: none;
    display: flex;
    margin-bottom: 0;
  }

  .mobile {
    ul {
      flex-direction: column;
      gap: 24px;
    }
  }

  .container {
    width: 100%;
    max-width: $containerWidth;
    padding: 0 $containerPadding;
    display: flex;
    justify-content: space-between;
    align-items: center;

    .logo {
      max-width: 150px;
      flex-shrink: 0;
      cursor: pointer;
    }
  }

  .items {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 56px;
    flex: 1;
  }

  .wrapper {
    display: flex;
    align-items: center;
    gap: 24px;
  }
}

.socialMedia {
  &.desktop {
    display: flex;
    align-items: center;
    gap: 16px;
    justify-content: center;
  }

  &.mobile {
    display: flex;
    align-items: flex-end;
    gap: 24px;
    justify-content: center;
    flex: 1;
  }
}

.link {
  font-weight: 700;
  color: var(--text-6);
  font-size: 16px;
  text-decoration: none;
  list-style: none;

  padding: 4px 0;
  position: relative;
  cursor: pointer;

  transition: color 0.3s ease;

  a {
    color: var(--text-6);
    text-decoration: none;
  }

  &:after {
    content: "";
    display: block;
    position: absolute;
    bottom: 0;
    left: 0;

    height: 2px;
    width: 0;
    background: #000;
    border-radius: 100%;

    transition: width 0.3s ease;
  }

  &:hover,
  &:focus {
    color: var(--text-4);
    outline: none;
    &:after {
      width: 100%;
    }
  }
}

.dropdown {
  position: relative;
  .dropdownTrigger {
    svg {
      transition: transform 200ms ease;
      &.rotateArrow {
        transform: rotate(180deg);
      }
    }
  }

  .dropdownContent {
    display: flex;
    flex-direction: column;

    background-color: #ffffff;
    padding: 16px 24px;
    box-shadow: 0px 5px 8px rgba(0, 0, 0, 0.1);
    z-index: 100;

    white-space: nowrap;

    position: absolute;
    top: 100%;
    left: 50%;
    transform: translateX(-50%);
  }
}

.mobile {
  display: none;
}

@media screen and (max-width: 1000px) {
  .desktop {
    display: none !important;
  }

  .mobile {
    display: block;
  }

  .openMobileMenu {
    display: flex;
    flex-direction: column;
    gap: 24px;

    position: absolute;
    top: 100%;
    left: 50%;
    transform: translateX(-50%);

    background-color: #ffffff;
    padding: 16px 24px;
    width: 100vw;
    height: calc(100vh - $navbarHeight);

    .link {
      font-size: 20px;
    }
  }
}
