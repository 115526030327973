@import "../../../../styles/_variables.scss";

.background {
  background-color: #f7f9fb;
}

.container {
  width: 100%;

  display: flex;
  flex-direction: column;

  padding: 72px $containerPadding;
  max-width: $containerWidth;
  margin: 0 auto;

  .title {
    color: var(--title-color);
    font-size: 40px;
    font-weight: 700;
    line-height: 60px;
    letter-spacing: -1.5px;
    margin-bottom: 16px;
  }

  .description {
    color: var(--text-color-4);
    font-size: 20px;
    font-weight: 400;
    line-height: 34px;
    letter-spacing: -0.5px;
    margin-bottom: 80px;
  }

  .wrapper {
    display: flex;
    flex-wrap: wrap;

    gap: 2px;

    min-height: 230px;
    border-radius: 10px;
    border: 1px solid #e5e5e5;
    overflow: hidden;

    text-align: center;
  }

  .item {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 16px;

    padding: 60px 34px;
    background: var(--basic-white, #fff);
    flex: 1 1 300px;

    transition: all 0.2s ease;

    &:hover {
      box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.05);
      transform: translateY(-4px);

      .itemTitle {
        color: var(--primary-color-main);
      }
    }
  }

  .itemTitle {
    color: var(--text-color-5, #37474f);
    text-align: center;
    font-size: 18px;
    font-weight: 700;
    line-height: 30px;
    letter-spacing: -0.5px;
  }

  .itemDescription {
    color: var(--text-color-4, #37474f);
    text-align: center;
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    letter-spacing: -0.5px;
  }
}
