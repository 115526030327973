@import "../../../../styles/_variables.scss";

.container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 170px;
  flex-wrap: wrap;

  width: 100%;
  max-width: $containerWidth;
  padding: 150px $containerPadding;
  margin: 0 auto;

  .card {
    display: flex;
    align-items: center;
    flex-direction: row-reverse;
    gap: 32px;

    width: 100%;

    &:nth-child(2n) {
      flex-direction: row;
    }

    &:hover {
      .image {
        transform: scale(1.05);
      }
    }
  }

  .wrapper {
    display: flex;
    flex-direction: column;
    gap: 24px;
  }

  .title {
    color: var(--title-color);
    font-size: 40px;
    font-weight: 700;
    letter-spacing: -0.5px;
  }

  .text {
    color: var(--text-color-3);
    font-size: 20px;
    font-weight: 400;
    line-height: 35px;
  }

  .link {
    display: flex;
    align-items: center;
    gap: 8px;

    font-size: 14px;
    font-weight: 700;
    letter-spacing: -0.5px;
    color: var(--primary-color-main);
    text-decoration: none;
    transition: color 0.3s ease;

    svg {
      transition: transform 0.3s ease;
    }

    &:hover {
      color: var(--primary-color-dark);
      svg {
        transform: translateX(5px);
      }
    }
  }

  .image {
    max-width: 463px;
    flex-shrink: 0;

    transition: all 0.3s ease;

    img {
      width: 100%;
      height: 100%;
      object-fit: contain;
    }
  }
}

@media screen and (max-width: 940px) {
  .card {
    flex-direction: column !important;
  }
}
