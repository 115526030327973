@import "../../../../styles/_variables.scss";

.background {
  display: flex;
  align-items: center;
  width: 100%;
  background: #eaf0fa;
  min-height: 694px;
  position: relative;

  .image {
    position: absolute;
    right: 0;
    top: 50%;
    width: 49%;

    transform: translateY(-50%);

    img {
      width: 100%;
      height: 100%;
      object-fit: contain;
    }
  }
}

.container {
  display: flex;
  flex: 1;

  max-width: $containerWidth;
  width: 100%;
  margin: 0 auto;
  padding: 40px $containerPadding;

  .wrapper {
    display: flex;
    flex-direction: column;
    gap: 16px;
    width: 50%;
    flex-shrink: 0;

    .title {
      color: #0d152e;
      font-size: 40px;
      font-weight: 700;
      line-height: 60px;
      letter-spacing: -1.5px;
      padding-bottom: 16px;
    }

    .subtitle {
      color: #81838c;
      font-size: 20px;
      font-style: normal;
      font-weight: 400;
      line-height: 34px;
      letter-spacing: -0.5px;
      padding-bottom: 40px;
    }

    .button {
      background-color: #416ff4;
      padding: 20px;
      border-radius: 8px;
      width: 100%;
      color: #fff;
      font-size: 16px;
      font-weight: 700;
      letter-spacing: -0.5px;
      text-decoration: none;
      text-align: center;
      transition: background-color 0.2s ease;

      &:hover {
        background-color: #2e5be8;
      }
    }
  }
}

.mobile {
  display: none;
}

@media screen and (max-width: 800px) {
  .background {
    flex-direction: column;

    .wrapper {
      width: 100%;
    }

    .image {
      width: calc(100% + 32px);
      margin-right: -32px;
      position: static;
      transform: none;

      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }
  }
  .mobile {
    display: flex;
    align-self: end;
  }
  .desktop {
    display: none;
  }
}
