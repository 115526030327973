@import "../../../../styles/variables.scss";

.background {
  display: flex;
  background: #333;
  position: relative;
  z-index: 0;
}

.image {
  position: absolute;
  top: 0;
  right: 0;
  width: 100%;
  height: 100%;
  z-index: -1;
  object-fit: cover;
  object-position: right;
}

.container {
  display: flex;
  align-items: center;
  width: 100%;
  max-width: $containerWidth;
  padding: 100px $containerPadding;
  margin: 0 auto;

  .title {
    color: var(--basic-white, #fff);
    font-size: 36px;
    font-weight: 500;
    line-height: 48px;
    letter-spacing: -0.5px;
    text-align: center;
  }
}
