@import "../../../../styles/_variables.scss";

.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 80px;

  max-width: $containerWidth;
  margin: 0 auto;
  padding: 48px $containerPadding 140px;

  .title {
    color: #0d152e;
    font-size: 40px;
    font-weight: 700;
    line-height: 60px;
    letter-spacing: -1.5px;
  }

  .wrapper {
    display: flex;
    justify-content: space-between;
    gap: 20px;
    flex-wrap: wrap;
    width: 100%;
  }
}
