@import "../../../../styles/_variables.scss";

.background {
  color: var(--basic-white, #fff);
  background-color: #f7f9fb;

  .container {
    width: 100%;
    max-width: $containerWidth;
    padding: 72px $containerPadding 120px;

    margin: 0 auto;
    text-align: center;

    .title {
      color: var(--title-color);
      font-size: 32px;
      font-weight: 700;
      line-height: 60px;
      letter-spacing: -1.5px;
      margin-bottom: 80px;
    }

    .tabs {
      display: flex;
      justify-content: center;
      align-items: center;
      flex-wrap: wrap;

      .tab {
        color: var(--title-color);
        font-size: 18px;
        font-weight: 400;
        line-height: 24px;
        text-align: center;
        border-bottom: 2px solid #e6e6e6;
        flex: 1 1 140px;
        padding: 8px;

        transition: all 0.2s ease;

        &.active {
          border-color: var(--primary-color-main);
          font-weight: 700;
        }
      }
    }

    .content {
      display: flex;
      flex-wrap: wrap;
      gap: 48px;
      justify-content: space-between;
      align-items: center;
      min-height: 600px;
      padding-top: 40px;
    }

    .wrapper {
      display: flex;
      flex-direction: column;
      gap: 24px;
      text-align: start;
      flex: 1 1 400px;
    }

    .contentTitle {
      color: var(--title-color);
      font-size: 40px;
      font-weight: 700;
      line-height: 60px;
      letter-spacing: -1.5px;
    }

    .contentText {
      color: var(--text-color-4);
      font-size: 20px;
      font-weight: 400;
      line-height: 34px;
      letter-spacing: -0.5px;
    }

    .image {
      display: flex;
      justify-content: center;
      flex: 1 0 min(500px, 100%);
    }
  }
}
