.image {
  position: relative;

  background-color: #d31d89;
  width: 100%;
  max-width: 500px;
  aspect-ratio: 1.75;
  border-radius: 8px;
  flex-shrink: 0;

  img {
    position: absolute;
    top: -12px;
    left: -12px;
    width: 100%;
    height: 100%;
    border-radius: 8px;
    object-fit: cover;
    transition: transform 0.2s ease-in-out;
  }

  &:hover {
    img {
      transform: translate(-8px, -8px);
    }
  }
}
