@import "../../../../styles/_variables.scss";

.container {
  display: flex;
  flex-direction: column;
  gap: 48px;

  width: 100%;
  max-width: $containerWidth;
  padding: 100px $containerPadding 150px;
  margin: 0 auto;

  .title {
    color: var(--title-color);
    font-size: 40px;
    font-weight: 700;
    line-height: 60px;
    letter-spacing: -1.5px;
  }

  .wrapper {
    display: grid;
    gap: 32px 100px;
    grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
    max-width: 900px;
    width: 100%;
    margin: 0 auto;
  }

  .item {
    display: flex;
    align-items: center;
    gap: 16px;

    color: var(--text-color-4);
    font-size: 20px;
    font-weight: 500;
    line-height: 30px;
    letter-spacing: -1.5px;

    transition: color 0.2s ease-in-out;

    &:hover {
      color: var(--primary-color-dark);

      .icon {
        transform: scale(1.1);
      }
    }
  }

  .icon {
    display: flex;
    align-items: center;
    justify-content: center;

    width: 62px;
    height: 62px;

    background: var(--primary-color-gradient);
    border-radius: 12px;

    transition: transform 0.2s ease-in-out;
  }
}
