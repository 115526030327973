@import "../../../../styles/_variables.scss";

.background {
  background: #f7f9fb;
}

.container {
  display: flex;
  flex-direction: column;
  gap: 90px;

  width: 100%;
  max-width: $containerWidth;
  padding: 80px $containerPadding 128px;
  margin: 0 auto;

  .header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex: 1;

    .title {
      color: #0d152e;
      font-size: 40px;
      font-weight: 700;
      line-height: 60px;
      letter-spacing: -1.5px;
    }

    .arrows {
      display: flex;
      align-items: center;
      gap: 16px;
    }

    .arrow {
      &:first-child {
        transform: rotate(180deg);
      }
      &:disabled {
        opacity: 0.5;
      }
    }
  }

  .carousel {
    display: flex;
    overflow: hidden;
    scroll-behavior: smooth;

    .image {
      width: 200px;
      flex-shrink: 0;
      margin-right: 70px;

      img {
        width: 100%;
        height: 100%;
        object-fit: contain;
      }
    }
  }
}
