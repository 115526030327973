@import "../../../../styles/_variables.scss";

.background {
  color: var(--basic-white, #fff);
  background-color: #f7f9fb;

  .container {
    width: 100%;
    max-width: $containerWidth;
    padding: 72px $containerPadding 120px;

    margin: 0 auto;

    svg {
      width: 42px;
    }
  }

  .title {
    color: var(--title-color);
    font-size: 32px;
    font-weight: 700;
    line-height: 60px;
    letter-spacing: -1.5px;
    margin-bottom: 36px;
    text-align: center;
  }

  .items {
    display: flex;
    flex-wrap: wrap;
    gap: 48px;
    justify-content: space-between;
    align-items: center;
    position: relative;
  }

  .item {
    display: flex;
    flex-direction: column;
    gap: 24px;
    width: 100%;
    max-width: 400px;

    .subtitle {
      color: var(--title-color);
      font-size: 24px;
      font-weight: 500;
      line-height: 27px;
      letter-spacing: -1px;
    }

    .description {
      color: var(--text-color-4);
      font-size: 16px;
      font-weight: 400;
      line-height: 34px;
      letter-spacing: -0.5px;
    }
  }
  .path {
    display: none;
  }
}

@media screen and (min-width: 925px) {
  .item {
    &:nth-child(2) {
      transform: translateY(50%);
    }
  }
  .path {
    display: flex !important;
    flex-direction: column;
    align-items: center;

    position: absolute;
    left: 50%;

    transform: translateX(-50%);
    width: 8px;
    height: 100%;

    .circle {
      width: 16px;
      height: 16px;
      border-radius: 50%;
      background-color: var(--primary-color-main);
    }

    hr {
      border: 0;
      border-right: 4px dashed var(--primary-color-main);
      flex: 1;
      opacity: 1;
    }
  }
}
