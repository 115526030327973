@import "../../../../../styles/_variables.scss";

.background {
  display: flex;
  align-items: center;

  position: relative;

  height: 100%;
  min-height: 707px;

  color: var(--basic-white, #fff);
  background-color: var(--basic-black);
  z-index: 0;

  .container {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    gap: 32px;
    width: 100%;
    max-width: $containerWidth;
    padding: 80px $containerPadding;
    margin: 0 auto;
  }

  .wrapper {
    display: flex;
    flex-direction: column;
    flex: 1 0 min(600px, 100%);
  }

  .title {
    color: var(--basic-white, #fff);
    font-size: 54px;
    font-weight: 600;
    line-height: 65px;
    letter-spacing: -1.5px;
    margin: 80px 0 16px;
  }

  .subtitle {
    color: var(--basic-white, #fff);
    font-size: 22px;
    font-weight: 400;
    line-height: 40px;
    letter-spacing: -0.5px;
  }

  .image {
    position: absolute;
    top: 0;
    right: 0;
    width: 100%;
    height: 100%;
    z-index: -1;

    object-fit: cover;
  }

  .form {
    display: flex;
    flex-direction: column;
    flex: 1 0 min(400px, 100%);
    border-radius: 8px;
    background: #333;
    padding: 64px 32px;

    fieldset {
      flex: 1;
    }

    .legend {
      color: var(--basic-white, #fff);
      text-align: center;
      font-size: 48px;
      font-weight: 700;
      line-height: 120%;
      text-align: left;
      margin-bottom: 8px;
    }

    .text {
      color: var(-text-color-2);
      font-size: 16px;
      font-weight: 500;
      line-height: 150%;
      margin-bottom: 16px;
    }

    .input {
      border-radius: 8px;
      border: 1px solid #e5e5e5;
      background: var(--basic-white, #fff);
      height: 60px;
      color: var(--text-color-4);
      font-size: 16px;
      font-weight: 400;
      letter-spacing: -0.5px;
      width: 100%;
      padding: 16px;
      margin-bottom: 16px;

      ~ textarea {
        height: 140px;
      }
    }
  }
}
