@import "../../../../styles/_variables.scss";

.container {
  display: flex;
  align-items: center;
  gap: 64px;
  max-width: $containerWidth;
  width: 100%;
  margin: 0 auto;
  padding: 64px $containerPadding;
  min-height: 700px;
  position: relative;

  .arrow {
    position: absolute;
    top: 0;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: -1;
    cursor: pointer;
  }

  .wrapper {
    display: flex;
    flex-direction: column;
    gap: 16px;
    flex-shrink: 0;

    max-width: 693px;
    width: 100%;
    flex: 1;

    .title {
      color: #162647;
      font-size: 36px;
      font-weight: 700;
      letter-spacing: -0.5px;
    }

    .description {
      color: #81838c;
      font-size: 20px;
      font-weight: 400;
      line-height: 35px;
    }
  }

  .image {
    display: flex;
    align-items: flex-end;
    justify-content: flex-end;
    flex: 1;

    img {
      width: 100%;
      height: 100%;
      object-fit: contain;
    }
  }
}

@media screen and (max-width: 800px) {
  .container {
    flex-direction: column;
    gap: 32px;
    min-height: 0;
  }
}
