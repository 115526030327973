.opened {
  display: block;
}

.closed {
  display: none;
}

.modal {
  width: 370px;
  height: 445px;
  background-image: url("../../assets/images/modal-whatsapp-background.svg");
  background-position: center;
  background-repeat: no-repeat;
  border-radius: 24px;
  padding: 28px 24px;
  position: fixed;
  bottom: 100px;
  right: 5%;
  z-index: 100;

  .content {
    position: relative;

    h1 {
      color: var(--basic-white, #fff);
      font-family: DM Sans;
      font-weight: 900;
      font-size: 1.375rem;
      line-height: 24px;
      letter-spacing: -1.58px;
      text-align: center;
    }

    .icon {
      position: absolute;
      top: -10px;
      right: -10px;
      cursor: pointer;
    }

    .column {
      margin-top: 1.25rem;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;

      .button {
        width: 100%;
        height: 52;
        background-color: #ff226c;
        border-radius: 40px;
        padding-top: 1rem;
        padding-right: 2rem;
        padding-bottom: 1rem;
        padding-left: 2rem;
        text-align: center;
        margin: 6px 0px;
        font-size: 1rem;
        color: var(--basic-white, #fff);
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        gap: 5px;
        cursor: pointer;
        text-decoration: none;
        outline: none;
        user-select: none;

        &:hover {
          animation-timing-function: linear;
          transform: scale(1.04, 1.04);
        }
      }
    }
  }

  @media screen and (max-width: 410px) {
    width: 90%;
    height: 460px;
    bottom: 90px;

    .content .column {
      margin-top: 0.625rem;
    }
  }
}

.container {
  padding: 0 16px;
  width: 64px;
  height: 64px;
  flex-shrink: 0;
  border-radius: 20px;
  background-color: #009250;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
  color: var(--basic-white, #fff);
  gap: 8px;
  text-align: center;
  font-size: 18px;
  font-weight: 700;
  letter-spacing: -0.5px;
  text-decoration: none;
  user-select: none;
  outline: none;
  cursor: pointer;

  transition: background-color 0.2s ease;

  position: fixed;
  bottom: 20px;
  right: 5%;
  z-index: 99;

  transition: width 0.2s ease;

  * {
    margin: 0;
  }

  svg {
    flex-shrink: 0;
  }

  p {
    display: none;
    white-space: nowrap;
  }

  &:hover {
    background-color: #008040;
    width: 190px;

    p {
      display: block;
    }
  }
}
