@import "../../../../styles/_variables.scss";

.background {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  background: #f7f9fb;

  text-align: center;

  .container {
    max-width: $containerWidth;
    width: 100%;
    padding: 48px $containerPadding 70px;

    .wrapper {
      display: flex;
      flex-direction: column;
      gap: 16px;

      h6 {
        color: #0d152e;
        font-size: 40px;
        font-weight: 700;
        line-height: 60px;
        letter-spacing: -1.5px;
      }

      p {
        font-weight: 400;
        line-height: 34px;
        font-size: 20px;
        color: var(--text-color-4);
      }
    }

    .cards {
      display: flex;
      justify-content: center;
      gap: 28px;
      flex-wrap: wrap;
    }

    .card {
      display: flex;
      flex-direction: column;
      align-items: center;
      gap: 16px;
      flex-shrink: 0;

      padding: 48px 16px 24px;
      width: 340px;
      border: 1px solid #ce0c9a;
      border-radius: 12px;

      transition: all 0.2s ease-in-out;

      &:hover {
        transform: scale(1.02);
        box-shadow: 0px 4px 16px rgba(0, 0, 0, 0.08);
      }

      .title {
        color: #162647;
        font-size: 22px;
        font-weight: 500;
        letter-spacing: -1px;
        line-height: 115%;
      }

      .description {
        color: var(--text-color-4);
        font-size: 16px;
        font-weight: 400;
        letter-spacing: -0.66px;
        line-height: 34px;
      }
    }
  }
}
