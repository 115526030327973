.container {
  display: flex;
  flex-direction: column;
  gap: 32px;
  max-width: 500px;
  width: 100%;

  background-color: #242222;
  height: fit-content;

  button,
  p {
    all: unset;

    white-space: pre-line;
  }

  svg {
    flex-shrink: 0;
  }

  .title {
    color: var(--basic-white, #fff);
    font-size: 24px;
    font-weight: 700;
    line-height: 140%;
  }

  .item {
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 12px;

    width: 100%;
    background: #242222;
    padding: 16px 0;
    color: #fff;

    .trigger {
      display: flex;
      justify-content: space-between;
      align-items: center;

      width: 100%;

      font-size: 20px;
      font-weight: 500;
    }

    .content {
      font-size: 16px;
      font-weight: 400;
      line-height: 34px;
      overflow: hidden;

      &[data-state="open"] {
        animation: slideDown 300ms ease-out;
      }
      &[data-state="closed"] {
        animation: slideUp 300ms ease-out;
      }
    }
  }

  .border {
    width: 100%;
    border: 0;
    height: 2px;
    background-color: #fff;
  }
}

@keyframes slideDown {
  from {
    height: 0;
  }
  to {
    height: var(--radix-accordion-content-height);
  }
}

@keyframes slideUp {
  from {
    height: var(--radix-accordion-content-height);
  }
  to {
    height: 0;
  }
}
