@import "../../../../styles/_variables.scss";

.container {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 72px;

  padding: 96px $containerPadding;
  max-width: $containerWidth;

  margin: 0 auto;

  .wrapper {
    display: flex;
    flex-direction: column;
    gap: 24px;
    max-width: 568px;
    width: 100%;

    h3 {
      font-size: 36px;
      color: #0d152e;
      font-weight: 700;
      line-height: 54px;
      letter-spacing: -1.5px;
    }

    p {
      font-size: 20px;
      color: var(--text-color-4);
      font-weight: 400;
      line-height: 34px;
    }
  }

  .image {
    display: flex;
    width: 100%;
    @media screen and (max-width: 900px) {
      display: none;
    }
  }
}
