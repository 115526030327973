@import "../../../../styles/_variables.scss";

.background {
  display: flex;
  align-items: center;
  width: 100%;
  background: #eaf0fa;

  * {
    margin: 0;
  }

  fieldset {
    border: 0;
  }
}

.container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 40px;
  flex: 1;
  flex-wrap: wrap;

  max-width: $containerWidth;
  width: 100%;
  margin: 0 auto;
  padding: 70px $containerPadding;

  .wrapper {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 16px;
    flex-shrink: 0;

    max-width: 520px;
    width: 100%;
    margin: 0 auto;
  }

  .title {
    color: #162647;
    font-size: 42px;
    font-weight: 700;
    letter-spacing: -1.5px;
  }

  .description {
    color: #81838c;
    font-size: 20px;
    font-weight: 400;
    line-height: 34px;
    letter-spacing: -0.5px;
  }

  .form {
    display: flex;
    flex-direction: column;
    margin: 0 auto;
    max-width: 520px;
    width: 100%;

    fieldset {
      display: flex;
      flex-direction: column;
      gap: 16px;
      flex: 1;
      min-width: fit-content;

      margin-bottom: 44px;
    }

    .error {
      color: red;
      font-size: 12px;
      margin-bottom: 20px;
    }
  }

  .formTitle {
    color: #162647;
    font-size: 36px;
    font-weight: 700;
    letter-spacing: -1.5px;
    margin-bottom: 8px;
  }

  .input {
    border-radius: 8px;
    border: 0.5px solid var(--text-2, #cfd8dc);
    padding: 16px 32px;
    height: 55px;

    font-size: 16px;
    font-weight: 500;
    color: var(--text-4, #455a64);
    background: var(--basic-white, #fff);

    &::placeholder {
      color: var(--text-4, #455a64);
    }

    ~ textarea {
      height: 140px;
    }

    &:focus {
      outline: 1px solid #416ff4;
    }
  }

  .button {
    flex-shrink: 0;
    border-radius: 8px;
    background: #416ff4;
    height: 60px;
    width: 100%;

    color: #fff;
    font-size: 16px;
    font-weight: 700;
    letter-spacing: -0.5px;

    transition: background 0.2s ease;

    &:hover {
      background: #2e5be8;
    }
  }
}
