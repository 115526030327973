@import "../../../../styles/_variables.scss";

.background {
  display: flex;
  align-items: center;

  position: relative;

  height: 100%;
  min-height: 707px;

  color: var(--basic-white, #fff);
  background-color: var(--basic-black);
  z-index: 0;

  .container {
    width: 100%;
    max-width: $containerWidth;
    padding: 80px $containerPadding;
    margin: 0 auto;
  }

  a {
    text-decoration: none;
  }

  .wrapper {
    width: 100%;
    max-width: 600px;
  }

  .title {
    font-size: 40px;
    font-style: normal;
    font-weight: 700;
    line-height: 60px;
    letter-spacing: -1.5px;
    margin-bottom: 16px;
  }

  .text {
    font-size: 20px;
    font-weight: 400;
    line-height: 34px;
    letter-spacing: -0.5px;
    margin-bottom: 40px;
  }

  .background {
    position: absolute;
    top: 0;
    right: 0;
    width: 100%;
    height: 100%;
    z-index: -1;

    object-fit: cover;
  }
}
