@import "../../../../styles/_variables.scss";

.container {
  width: 100%;
  background-color: #fff;
  display: flex;
  flex-direction: column;
  gap: 20px;
  justify-content: center;
  max-width: $containerWidth;
  padding: 70px $containerPadding 120px;
  margin: 0 auto;

  .title {
    color: var(--title-color);
    font-size: 40px;
    font-weight: 700;
    line-height: 60px;
    letter-spacing: -1.5px;
    margin-bottom: 20px;
  }

  .arrows {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    gap: 16px;

    > :first-child {
      transform: rotate(180deg);
    }

    > :disabled {
      opacity: 0.5;
    }
  }

  .carousel {
    display: flex;
    gap: 4px;
    overflow: hidden;
    scroll-behavior: smooth;
  }

  .item {
    display: flex;
    flex-direction: column;
    gap: 20px;
    flex-shrink: 0;
    width: 100%;
  }

  .place {
    color: var(--basic-black, #1f1d1d);
    font-size: 24px;
    font-weight: 700;
    letter-spacing: -0.5px;
  }

  .images {
    display: flex;
    align-items: center;
    gap: 12px;
    flex-wrap: wrap;
  }

  .image {
    flex: 1;
    position: relative;
    min-width: 280px;
    width: 100%;
    border-radius: 16px;
    overflow: hidden;

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }

  .address {
    color: var(--basic-black, #1f1d1d);
    font-size: 16px;
    font-weight: 400;
    letter-spacing: -0.5px;
  }
}
