.container {
  display: flex;
  flex-direction: column;
  gap: 32px;
  margin: 0 auto;

  button {
    all: unset;
  }

  .item {
    display: flex;
    align-items: center;
    gap: 8px;
    cursor: pointer;

    .indicator {
      position: relative;
      width: 80px;
      height: 80px;

      display: flex;
      align-items: center;
      justify-content: center;
    }

    .label {
      font-size: 28px;
      font-weight: 700;
      background: linear-gradient(
        125deg,
        #aa7f9e -30.75%,
        #d82e78 54.15%,
        #e35353 162.55%,
        #eb6d38 263.43%,
        #f07d28 352.98%,
        #f28322 421.95%
      );

      background-clip: text;
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
    }
  }
}
