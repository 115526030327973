.content {
  display: flex;
  flex-direction: column;
  max-width: 757px;
  width: 100%;
  padding-bottom: 90px;

  .subtitle {
    color: #162647;
    font-size: 36px;
    font-weight: 700;
    letter-spacing: -0.5px;
    margin-bottom: 16px;
  }

  .subtitleText {
    color: #81838c;
    font-size: 20px;
    font-weight: 400;
    line-height: 35px;
    margin-bottom: 48px;
  }

  .checklist {
    display: flex;
    flex-direction: column;
    gap: 24px;

    .checklistItem {
      display: flex;
      align-items: center;
      gap: 16px;
      align-self: stretch;
    }

    .checklistItemText {
      color: #162647;
      font-size: 20px;
      font-weight: 700;
      letter-spacing: -0.5px;
    }

    .icon {
      flex-shrink: 0;
    }
  }
}
