.container {
  display: flex;
  flex-direction: column;

  background-color: var(--basic-white);

  position: fixed;
  top: 0;
  right: 0;

  width: 640px;
  height: 100vh;

  z-index: 100;

  * {
    margin: 0;
  }

  .panel {
    display: flex;
    flex-direction: column;
    height: 100%;
  }
}

.overlay {
  position: fixed;
  inset: 0;
  width: 100vw;
  height: 100vh;

  background-color: rgba(0, 0, 0, 0.5);

  z-index: 99;
}
