.header {
  display: flex;
  align-items: center;
  gap: 16px;

  padding: 32px;
}

form {
  display: flex;
  flex: 1;
  overflow-y: auto;
  padding: 32px;
}

.error {
  color: red;
  font-size: 12px;
  margin-bottom: 20px;
}

.content {
  display: flex;
  flex-direction: column;
  gap: 32px;
  flex: 1;

  width: 100%;
}

.legend {
  color: var(--text-color-6, #263238);
  font-size: 20px;
  font-weight: 500;
  letter-spacing: -0.5px;
  margin-bottom: 24px !important;
}

.fields {
  display: flex;
  flex-direction: column;
  gap: 16px;

  input,
  textarea {
    border-radius: 8px;
    border: 1px solid var(--text-color-1, #e8ecee);
    color: var(--text-color-4);
    font-size: 16px;
    font-weight: 400;
    letter-spacing: -0.5px;
    min-height: 60px;
    padding: 20px 30px;

    &::placeholder {
      color: var(--text-color-3, #78909c);
    }
  }

  .file {
    position: relative;

    input {
      opacity: 0;
      position: absolute;
    }

    label {
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 12px;
      text-align: center;
      border: 2px dashed var(--text-color-1, #e8ecee);
      height: 90px;
    }
  }
}

.button {
  margin: 0 auto !important;
  padding-bottom: 32px;
}
