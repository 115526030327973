.wrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 32px;
  flex-shrink: 0;

  max-width: 764px;
  width: 100%;
  padding: 64px 32px;
  border-radius: 16px;
  background: var(--basic-white, #fff);

  .title {
    color: #000;
    font-size: 28px;
    font-weight: 700;
    letter-spacing: -0.5px;
  }

  .item {
    display: flex;
    align-items: center;
    gap: 16px;
  }

  .text {
    color: #162647;
    font-size: 20px;
    font-weight: 700;
    letter-spacing: -0.5px;
  }

  svg {
    flex-shrink: 0;
  }
}
