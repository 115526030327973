@import "../../../../styles/_variables.scss";

.container {
  display: flex;
  flex-direction: column;
  align-items: center;

  width: 100%;
  max-width: $containerWidth;
  padding: 72px $containerPadding 120px;
  margin: 0 auto;

  * {
    margin: 0;
  }

  .title {
    width: 100%;
    font-size: 40px;
    margin-bottom: 80px;
  }

  .content {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 66px;
    flex-wrap: wrap;

    .item {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      gap: 24px;
      width: 100%;
      max-width: 330px;
      margin: 0 auto;
    }

    .itemTitle {
      color: #162647;
      font-size: 24px;
      font-weight: 700;
      line-height: 115%;
    }
  }
}
