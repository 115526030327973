@import "../../../../styles/_variables.scss";

.container {
  display: flex;
  flex-direction: column;
  align-items: center;

  width: 100%;
  max-width: $containerWidth;
  padding: 72px $containerPadding 120px;
  margin: 0 auto;

  * {
    margin: 0;
  }

  .title {
    color: #0d152e;
    font-size: 32px;
    font-weight: 700;
    line-height: 60px;
    letter-spacing: -1.5px;
    margin-bottom: 16px;
  }

  .description {
    color: #1a1a1a;
    text-align: center;
    font-size: 20px;
    font-weight: 500;
    line-height: 26px;
    letter-spacing: 0.2px;
    margin-bottom: 60px;
    white-space: pre-line;
  }

  .content {
    display: flex;
    flex-wrap: wrap;
    gap: 40px;
  }

  .card {
    background-color: var(--primary-color-main);
    color: var(--basic-white);
    padding: 40px;
    border-radius: 16px;
    box-shadow: 20px 20px 50px 0px rgba(0, 0, 0, 0.1);

    display: flex;
    flex-direction: column;
    gap: 24px;

    flex: 1 0 min(420px, 100%);

    transition: transform 0.2s ease, box-shadow 0.2s ease;

    &:hover {
      transform: translateY(-4px);
      box-shadow: 20px 20px 50px 0px rgba(0, 0, 0, 0.2);
    }
  }

  .cardTitle {
    color: var(--basic-white, #fff);
    font-size: 24px;
    font-weight: 700;
    line-height: 115%;
    letter-spacing: -1px;
  }

  .cardDescription {
    color: var(--basic-white, #fff);
    font-size: 16px;
    font-weight: 400;
    line-height: 34px;
    letter-spacing: 0.5px;
  }
}
