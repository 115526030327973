@import "../../../styles/_variables.scss";

.flex {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

.background {
  display: flex;
  align-items: center;
  position: relative;

  height: 100%;
  min-height: 724px;

  flex: 1;

  color: var(--basic-white, #fff);
  background-color: var(--basic-black);
  z-index: 0;

  .container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    width: 100%;
    max-width: $containerWidth;
    padding: 80px $containerPadding;
    margin: 0 auto;
  }

  .logo {
    width: 200px;
    margin-bottom: 48px;
  }

  .check {
    width: 135px;
    height: 135px;
    flex-shrink: 0;
    margin-bottom: 48px;
  }

  .title {
    color: var(--basic-white, #fff);
    text-align: center;
    font-size: 56px;
    font-weight: 700;
    line-height: 100%;
    margin-bottom: 8px;
  }

  .subtitle {
    color: var(--basic-white, #fff);
    text-align: center;
    font-size: 18px;
    font-weight: 500;
    line-height: 150%;
  }

  .image {
    position: absolute;
    top: 0;
    right: 0;
    width: 100%;
    height: 100%;
    z-index: -1;

    object-fit: cover;
  }
}
