@import "../../../styles/_variables.scss";

.loader {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;
  width: 100%;
}

.header {
  background: #f8fafc;
}

.container {
  width: 100%;
  max-width: $containerWidth;
  padding: 48px $containerPadding;
  margin: 0 auto;
}

.title {
  color: var(--title-color);
  font-size: 40px;
  font-weight: 700;
  letter-spacing: -1.5px;
  line-height: 60px;
  text-align: center;
  margin-bottom: 16px;
}

.wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 32px;
  text-align: center;

  p {
    display: flex;
    align-items: center;
    gap: 4px;
  }
}

.section {
  display: flex;
  flex-direction: column;
  gap: 16px;
}

.subtitle {
  color: var(--text-6, #263238);
  font-size: 32px;
  font-weight: 700;
  letter-spacing: -0.5px;
}

.items {
  display: flex;
  flex-direction: column;
  gap: 12px;

  * {
    font-size: 18px;
  }
}

.button {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 32px;
}
