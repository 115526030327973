@import "../../../../styles/_variables.scss";

.container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
  gap: 24px;

  width: 100%;
  max-width: $containerWidth;
  padding: 100px $containerPadding;
  margin: 0 auto;

  .image {
    max-width: 490px;
    width: 100%;
    aspect-ratio: 1 / 1;
    margin: 0 auto;

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }

  .wrapper {
    display: flex;
    flex-direction: column;
    gap: 24px;
    max-width: 550px;
    width: 100%;
    margin: 0 auto;
  }

  .title {
    color: var(--title-color);
    font-size: 40px;
    font-weight: 700;
    line-height: 60px;
    letter-spacing: -1.5px;
  }

  .description {
    color: var(--text-color-4);
    font-size: 20px;
    font-weight: 400;
    line-height: 34px;
    letter-spacing: -0.5px;
  }
}
