.form {
  display: flex;
  flex-direction: column;
  gap: 16px;
  flex-basis: min(500px, 100%);
  flex-grow: 1;
  flex-shrink: 0;

  border-radius: 22px;
  background: #fff;
  padding: 32px;
  width: 100%;

  .error {
    color: red;
    font-size: 12px;
    margin-bottom: 20px;
  }

  .input {
    border-radius: 8px;
    border: 1px solid #e5e5e5;
    padding: 24px 20px;
    font-size: 16px;
    font-weight: 400;
    letter-spacing: -0.5px;
    color: #242222;

    &::placeholder {
      color: #9ea1ab;
    }

    ~ textarea {
      height: 140px;
    }
  }
}
