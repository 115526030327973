@import "../../../../styles/_variables.scss";

.background {
  background: #f8fafc;
}

.wrapper {
  display: flex;
  flex-direction: column;
  gap: 80px;

  width: 100%;
  max-width: $containerWidth;
  padding: 72px $containerPadding 120px;
  margin: 0 auto;

  .title {
    color: #0d152e;
    font-size: 40px;
    font-weight: 700;
    line-height: 60px;
    letter-spacing: -1.5px;
  }

  .card {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 42px;
    flex-wrap: wrap-reverse;
    flex-shrink: 0;

    padding: 64px 100px;

    border-radius: 16px;
    background: #333;
    width: 100%;
    height: 100%;
  }

  .info {
    display: flex;
    flex-direction: column;

    flex: 1;
    width: 500px;
    max-width: 100%;
  }

  .quote {
    color: var(--basic-white, #fff);
    font-size: 24px;
    font-weight: 500;
    line-height: 36px;
    margin-bottom: 28px;
    letter-spacing: -0.5px;
    position: relative;
    width: 100%;
  }

  .quoteInit,
  .quoteEnd {
    position: absolute;
  }

  .quoteInit {
    top: -20px;
    left: -20px;
  }

  .quoteEnd {
    transform: rotate(180deg);
  }

  .name {
    color: var(--basic-white, #fff);
    font-size: 20px;
    font-weight: 700;
    line-height: 25px;
    margin-bottom: 10px;
    letter-spacing: -0.5px;
  }

  .company {
    color: rgba(255, 255, 255, 0.7);
    font-size: 16px;
    font-weight: 500;
    line-height: 25px;
    letter-spacing: -0.5px;
  }

  .image {
    position: relative;
    width: 300px;
    height: 300px;
    max-width: 100%;

    border-radius: 50%;
    overflow: hidden;
    flex-shrink: 0;

    img {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }
}

@media screen and (max-width: 550px) {
  .card {
    padding: 32px !important;
  }
}
