.carousel {
  display: flex;
  gap: 16px;
  overflow: hidden;
  scroll-behavior: smooth;
  width: 100%;
}

.dots {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 12px;

  .dot,
  .activeDot {
    width: 12px;
    height: 12px;
    border-radius: 50%;
    cursor: pointer;
    transition: background 0.2s ease;
  }

  .dot {
    background: #d9d9d9;
  }

  .activeDot {
    background: var(--text-color-6, #263238);
  }
}
