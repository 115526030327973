@import "../../../../styles/_variables.scss";

.background {
  position: sticky;
  top: 0;

  height: 96px;
  box-shadow: 0px 1px 8px rgba(0, 0, 0, 0.1);

  z-index: 99;

  background-color: var(--basic-white);

  .container {
    width: 100%;
    padding: 0 $containerPadding;
    max-width: $containerWidth;
    margin: 0 auto;
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 100%;
  }
}
