.container {
  * {
    &::selection {
      background: #416ff4;
      color: #fff;
    }

    &:focus {
      outline: 1px solid #416ff4;
    }
  }
}
