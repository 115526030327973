.container {
  position: relative;

  .button {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 4px;
    flex-shrink: 0;

    width: 70px;
    height: 34px;
    border-radius: 56px;
    border: 2px solid #37474f;

    span {
      text-transform: uppercase;
    }

    svg {
      transition: transform 0.3s ease-in-out;

      &.open {
        transform: rotate(180deg);
      }
    }
  }

  .select {
    display: flex;
    flex-direction: column;

    width: 70px;

    position: absolute;
    top: 100%;

    background-color: #fff;
    border-radius: 12px;
    overflow: hidden;

    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
    z-index: 101;

    button {
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 4px;
      height: 28px;

      &:first-child {
        padding-top: 4px;
      }
      &:last-child {
        padding-bottom: 4px;
      }
      &:hover {
        background-color: #f5f5f5;
      }
    }

    .flag {
      width: 20px;
    }
  }
}
