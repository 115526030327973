@import "../../../../styles/_variables.scss";

.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 40px;

  max-width: $containerWidth;
  width: 100%;
  margin: 0 auto;
  padding: 48px $containerPadding 74px;

  .title {
    color: #0d152e;
    text-align: center;
    font-size: 36px;
    font-weight: 700;
    line-height: 60px;
    letter-spacing: -1.5px;
  }

  .content {
    width: 100%;
    height: 100%;
    max-width: 1000px;

    img {
      width: 100%;
      height: 100%;
      object-fit: contain;
    }
  }

  .button {
    border-radius: 8px;
    width: 317px;
    height: 60px;
    flex-shrink: 0;
    background: #416ff4;

    color: #fff;
    font-size: 16px;
    letter-spacing: -0.5px;
    font-weight: 700;

    transition: background 0.2s ease;

    &:hover {
      background-color: #2e5be8;
    }
  }
}
