@import "../../../../styles/_variables.scss";

.background {
  display: flex;
  align-items: center;
  width: 100%;
  background: #eaf0fa;
  overflow: hidden;
  position: relative;

  .arrow {
    position: absolute;
    top: 0;
    right: 50%;
    transform: translate(50%, -50%);
    z-index: 1;
  }
}

.container {
  display: flex;
  flex-direction: column;
  flex: 1;

  max-width: $containerWidth;
  width: 100%;
  margin: 0 auto;
  padding: 70px $containerPadding;
  z-index: 1;

  .title {
    color: #0d152e;
    text-align: center;
    font-size: 48px;
    font-weight: 700;
    line-height: 70px;
    margin-bottom: 140px;
  }
}

.image {
  position: absolute;
  right: 0;
  top: 35%;

  width: 32%;

  transform: translateY(-50%);

  img {
    width: 100%;
    height: 100%;
    object-fit: contain;
  }

  z-index: 0;

  @media (max-width: 1000px) {
    display: none;
  }
}
