@import "../../../../styles/_variables.scss";

.background {
  display: flex;
  align-items: end;
  min-height: 580px;
  height: 100%;
  background-color: var(--basic-black);

  .image {
    width: 100%;
    max-width: 1000px;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    background: var(--basic-black);
  }
}

.container {
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: space-between;
  gap: 24px;

  width: 100%;
  max-width: $containerWidth;
  margin: 0 auto;

  @media screen and (max-width: 940px) {
    flex-direction: column-reverse;
    padding: 40px;
  }

  .wrapper {
    display: flex;
    flex-direction: column;
    align-items: end;
    justify-content: end;
    width: 49%;

    @media screen and (max-width: 940px) {
      width: 100%;
    }
  }

  .title {
    color: var(--basic-white, #fff);
    font-size: 44px;
    font-weight: 700;
    line-height: 60px;
    letter-spacing: -1.5px;
  }

  .description {
    color: var(--basic-white, #fff);
    font-size: 18px;
    font-weight: 400;
  }
}
