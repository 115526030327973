@import "../../styles/_variables.scss";

.background {
  background: #242222;
  padding: 80px $containerPadding 115px;
}

.container {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 48px;
  flex-wrap: wrap;

  width: 100%;
  max-width: $containerWidth;
  margin: 0 auto;

  color: #fff;

  .wrapper {
    display: flex;
    flex-direction: column;
    gap: 48px;
    flex-basis: min(545px, 100%);
    flex-grow: 1;
    flex-shrink: 0;
  }

  .title {
    font-size: 42px;
    font-weight: 700;
    letter-spacing: -1.5px;
  }

  .description {
    font-size: 20px;
    font-weight: 400;
    line-height: 34px;
    letter-spacing: -0.5px;
  }
}
