.header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 48px;

  padding: 32px;
}

.title {
  color: var(--text-color-6, #263238);
  font-size: 24px;
  font-weight: 700;
  line-height: 27px;
}

.icons {
  display: flex;
  align-items: center;
  gap: 16px;
}

.content {
  display: flex;
  flex-direction: column;
  gap: 40px;
  padding: 32px;

  flex: 1;
  overflow-y: auto;
}

.section {
  display: flex;
  flex-direction: column;
  gap: 16px;
}

.subtitle {
  color: var(--text-6, #263238);
  font-size: 20px;
  font-weight: 500;
  letter-spacing: -0.5px;
}

.items {
  display: flex;
  flex-direction: column;
  gap: 12px;
}
