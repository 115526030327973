@import "../../../../styles/_variables.scss";

.container {
  width: 100%;
  padding: 80px $containerPadding;
  max-width: $containerWidth;
  margin: 0 auto;

  display: flex;
  align-items: center;
  justify-content: center;
  gap: 64px;
  flex-wrap: wrap-reverse;

  .wrapper {
    display: flex;
    flex-direction: column;
    gap: 16px;
    width: 100%;
    max-width: 525px;
  }

  .title {
    color: var(--title-color);
    font-size: 40px;
    font-weight: 700;
    line-height: 60px;
    letter-spacing: -1.5px;
  }

  .description {
    color: var(--text-color-4);
    font-size: 20px;
    font-weight: 400;
    line-height: 34px;
    letter-spacing: 0.5px;
  }

  .image {
    position: relative;
    width: 520px;
    aspect-ratio: 3/2;
    border-radius: 16px;
    overflow: hidden;

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }
}
