@import "../../../../styles/_variables.scss";

.container {
  width: 100%;
  max-width: $containerWidth;
  padding: 72px $containerPadding 120px;

  margin: 0 auto;

  .title {
    text-align: center;
    color: #0d152e;
    font-size: 32px;
    font-weight: 700;
    line-height: 60px;
    letter-spacing: -1.5px;
    margin-bottom: 100px;
  }

  .content {
    display: flex;
    flex-wrap: wrap;
    gap: 48px;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 48px;
  }

  .wrapper {
    display: flex;
    flex-direction: column;
    gap: 24px;
    text-align: start;
    flex: 1 1 400px;
  }

  .contentTitle {
    color: var(--title-color);
    font-size: 40px;
    font-weight: 700;
    line-height: 60px;
    letter-spacing: -1.5px;
  }

  .contentText {
    color: var(--text-color-4);
    font-size: 20px;
    font-weight: 400;
    line-height: 34px;
    letter-spacing: -0.5px;
  }

  .image {
    display: flex;
    justify-content: center;
    flex: 1 0 min(500px, 100%);
  }

  .items {
    display: flex;
    flex-wrap: wrap;
    gap: 48px;
    justify-content: space-between;
  }

  .item {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 24px;
    flex: 1 1 200px;

    color: var(--title-color);
    text-align: center;
    font-size: 18px;
    font-weight: 500;
    line-height: 115%;

    svg {
      transition: all 0.3s ease-in-out;
    }

    &:hover {
      svg {
        transform: rotateY(360deg);
      }
    }
  }
}
