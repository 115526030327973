@import "../../../../styles/_variables.scss";

.container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
  gap: 24px;

  width: 100%;
  max-width: $containerWidth;
  padding: 100px $containerPadding 50px;
  margin: 0 auto;

  .wrapper {
    display: flex;
    flex-direction: column;
    width: 100%;
    max-width: 554px;
    margin: 0 auto;
  }

  .title {
    color: #0d152e;
    font-size: 40px;
    font-style: normal;
    font-weight: 700;
    line-height: 60px;
    letter-spacing: -1.5px;
    margin-bottom: 16px;
  }

  .subtitle {
    color: var(-text-color-4);
    font-size: 20px;
    font-weight: 400;
    line-height: 34px;
    letter-spacing: -0.5px;
    margin-bottom: 24px;
  }

  .hashtag {
    color: var(--primary-color-dark);
  }

  a {
    text-decoration: none;
  }

  .image {
    position: relative;
    max-width: 500px;
    width: 100%;
    height: 380px;
    border-radius: 8px;
    overflow: hidden;
    margin: 0 auto;

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }
}
