.container {
  width: 100%;
  height: 100%;
  aspect-ratio: 16 / 9;
  background-color: grey;
  position: relative;

  video {
    height: 100%;
    width: 100%;
    position: absolute;
    top: 0;
  }
}
