@import "../../../../../styles/_variables.scss";

.background {
  background: #f7f9fb;
}

.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 32px;
  width: 100%;
  max-width: $containerWidth;
  padding: 80px $containerPadding 0;
  margin: 0 auto;

  .title {
    color: var(--title-color);
    text-align: center;
    font-size: 32px;
    font-weight: 700;
    line-height: 60px;
    letter-spacing: -1.5px;
  }

  .flex {
    display: flex;
    flex-wrap: wrap;
    gap: 32px;
  }

  .content {
    display: flex;
    flex-direction: column;
    gap: 32px;
    flex: 1 0 min(500px, 100%);
  }

  .item {
    display: flex;
    gap: 24px;

    &:hover {
      .icon {
        background-color: var(--primary-color-light);
        box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
      }
    }

    &:last-child {
      padding-bottom: 80px;
    }

    .icon {
      display: flex;
      justify-content: center;
      align-items: center;
      flex-shrink: 0;
      background-color: var(--primary-color-main);
      width: 56px;
      height: 56px;
      padding: 16px;
      border-radius: 50%;

      transition: background-color 0.3s ease, box-shadow 0.3s ease;
    }

    .wrapper {
      display: flex;
      flex-direction: column;
      gap: 8px;
    }

    .subtitle {
      color: var(--title-color);
      font-size: 20px;
      font-weight: 700;
      line-height: 150%;
    }

    .text {
      color: var(--text-color-4);
      font-size: 18px;
      font-weight: 500;
      line-height: 150%;
    }
  }

  .image {
    flex: 1 0 min(500px, 100%);

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }
}
