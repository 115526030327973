@import "../../../../styles/_variables.scss";

.background {
  background-color: #333333;
}

.container {
  width: 100%;
  padding: 72px $containerPadding;
  max-width: $containerWidth;
  margin: 0 auto;

  * {
    margin: 0;
  }

  .wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 30px;
    text-align: center;
    color: var(--basic-white);

    p {
      font-size: 16px;
      font-weight: 400;
      letter-spacing: -0.5px;
    }

    span {
      font-weight: 700;
    }
  }

  .back {
    display: flex;
    align-items: center;
    gap: 4px;
    text-decoration: none;
    color: var(--basic-white);
    margin-top: 32px;
    svg {
      transition: scale 0.2s ease-in-out;
    }

    &:hover {
      svg {
        scale: 1.3;
      }
    }
  }
}
