@import "../../../../../styles/_variables.scss";

.background {
  background-color: var(--primary-color-main);
}

.container {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 32px;
  width: 100%;
  max-width: $containerWidth;
  padding: 80px $containerPadding;
  margin: 0 auto;

  .card {
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 48px;
    gap: 36px;
    border-radius: 10px;
    background: var(--basic-white, #fff);

    flex: 1;

    transition: transform 0.3s ease-in-out;

    &:hover {
      transform: translateY(-10px);
    }

    .title {
      color: #0d152e;
      text-align: center;
      font-size: 28px;
      font-weight: 700;
      line-height: 60px;
      letter-spacing: -1.5px;
    }

    .item {
      display: flex;
      align-items: center;
      gap: 8px;
    }

    .icon {
      flex-shrink: 0;
    }
  }
}

@media screen and (max-width: 1000px) {
  .container {
    flex-direction: column !important;
  }
}
