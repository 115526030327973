.content {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  flex: 1;
  padding: 32px;
  text-align: center;

  .title {
    color: var(--title-color);
    font-size: 32px;
    font-weight: 700;
    line-height: 44px;
    letter-spacing: -1.5px;
    margin-bottom: 8px;
  }

  .text {
    color: var(--text-color-4);
    font-size: 20px;
    font-weight: 400;
    letter-spacing: -0.5px;
    margin-bottom: 32px;
  }
}
