@keyframes slide-in {
  0% {
    right: -200px;
    opacity: 0;
  }
  100% {
    right: 10px;
    opacity: 1;
  }
}

@keyframes slide-out {
  0% {
    right: 10px;
    opacity: 1;
  }
  100% {
    right: -200px;
    opacity: 0;
  }
}

.alertComponent {
  z-index: 999;
  position: fixed;
  bottom: 60px;
  right: 10px;
  width: 470px;
  padding: 16px;
  animation: slide-in 1s ease-out forwards, slide-out 1s ease-out forwards 5s;
  font-size: 16px;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  border-radius: 6px;
  gap: 7px;
  -webkit-touch-callout: none; 
    -webkit-user-select: none;
     -khtml-user-select: none; 
       -moz-user-select: none; 
        -ms-user-select: none; 
            user-select: none;

  &.success {
    background-color: var(--success-color-background);
    color: var(--success-color-main);
    border-left: 8px solid var(--success-color-main);
  }

  &.warning {
    background-color: var(--warning-color-background);
    color: var(--warning-color-main);
    border-left: 8px solid var(--warning-color-main);
  }

  &.error {
    background-color: var(--error-color-background);
    color: var(--error-color-main);
    border-left: 8px solid var(--error-color-main);
  }

  img {
    width: 18px;
    height: 18px;
    margin-right: 10px;
  }

  p {
    margin: 0px;
  }

  .column {
    display: flex;
    flex-direction: column;
    justify-content: center;

    .icon {
      margin-top: 3px;
    }

    p,
    h3 {
      margin: 0;
      text-align: start;
    }
  }

  @media (max-width: 768px) {
    width: 85%;
  }
}
