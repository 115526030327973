@import "../../../../styles/_variables.scss";

.background {
  background: #242222;
  padding: 80px $containerPadding 115px;

  * {
    margin: 0;
  }

  .container {
    display: flex;
    justify-content: center;
    gap: 100px;
    flex-wrap: wrap;

    width: 100%;
    max-width: $containerWidth;
    margin: 0 auto;
  }
}
