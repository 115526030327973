@import "../../../../styles/_variables.scss";

.container {
  max-width: $containerWidth;
  width: 100%;
  margin: 0 auto;
  padding: 48px $containerPadding;

  .title {
    color: #0d152e;
    text-align: center;
    font-size: 36px;
    font-weight: 700;
    line-height: 60px;
    letter-spacing: -1.5px;
    margin-bottom: 46px;
  }

  .items {
    display: flex;
    gap: 32px;
    justify-content: space-between;
    flex-wrap: wrap;
  }

  .item {
    display: flex;
    flex-direction: column;
    gap: 18px;

    max-width: 463px;
    margin: 0 auto;

    .wrapper {
      display: flex;
      align-items: center;
      gap: 16px;

      .subtitle {
        color: #162647;
        font-size: 20px;
        font-weight: 700;
        letter-spacing: -0.5px;
      }
    }
  }
}
