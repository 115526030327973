.container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 100%;
}

.loader {
  width: 30px;
  height: 30px;

  display: inline-block;

  animation: rotation 1s linear infinite;
  border-radius: 50%;

  &.primary {
    border: 5px solid var(--basic-white);
    border-bottom-color: transparent;
  }

  &.secondary {
    border: 5px solid var(--primary-color-light);
    border-bottom-color: transparent;
  }
}

@keyframes rotation {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
