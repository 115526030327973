:root {
  // Basic Color Palette
  --basic-black: #1f1d1d;
  --basic-white: #ffffff;
  --basic-grey: #f6f6f6;
  --basic-background: #fafdff;

  // Color Palette | Primary
  --primary-color-dark: #d42087;
  --primary-color-main: #d31d89;
  --primary-color-light: #de4363;
  --primary-color-background: #fff1f6;
  --primary-color-gradient: linear-gradient(
    113.56deg,
    var(--primary-color-light) 0.64%,
    var(--primary-color-dark) 90.54%
  );

  // Color Palette | Secondary
  --secondary-color-dark: #d74c3f;
  --secondary-color-main: #ee752f;
  --secondary-color-light: #f8ad3e;
  --secondary-color-background: #fff5ef;
  --secondary-color-gradient: linear-gradient(
    113.56deg,
    var(--secondary-color-light) 0.64%,
    var(--secondary-color-dark) 90.54%
  );

  // Color Palette | Text
  --text-color-6: #263238;
  --text-color-5: #37474f;
  --text-color-4: #455a64;
  --text-color-3: #81838c;
  --text-color-2: #cfd8dc;
  --text-color-1: #e8ecee;

  // Color Palette | Sucess
  --success-color-dark: #005024;
  --success-color-main: #006f38;
  --success-color-light: #009250;
  --success-color-background: #e1f3ea;

  // Color Palette | Warning
  --warning-color-dark: #eeab00;
  --warning-color-main: #fcc332;
  --warning-color-light: #ffd772;
  --warning-color-background: #fff8e8;

  // Color Palette | Error
  --error-color-dark: #aa2929;
  --error-color-main: #eb5959;
  --error-color-light: #f97f7f;
  --error-color-background: #ffefef;

  //Title color
  --title-color: #0d152e;
}
