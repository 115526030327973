@import "../../../../styles/_variables.scss";

.background {
  position: relative;
  display: flex;
  align-items: center;

  height: 100%;
  min-height: 700px;

  color: var(--basic-white, #fff);
  background-color: var(--basic-black);
  z-index: 0;

  .container {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 64px;
    flex-wrap: wrap;

    width: 100%;
    max-width: $containerWidth;
    padding: 80px $containerPadding;
    margin: 0 auto;
    overflow: hidden;
  }

  .wrapper {
    display: flex;
    flex-direction: column;
    gap: 24px;
    color: var(--basic-white, #fff);
    max-width: 700px;
    flex-grow: 1;
    margin: 0 auto;
  }

  .title {
    font-size: 56px;
    font-weight: 700;
    line-height: 60px;
    letter-spacing: -1.5px;
  }

  .description {
    font-size: 20px;
    font-weight: 400;
    line-height: 35px;
  }

  .image {
    width: 250px;
    margin: 0 auto;
    animation: rotation 30s infinite linear;

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }

    transition: scale 0.3s ease;

    &:hover {
      scale: 1.1;
    }
  }

  .backgroundImage {
    display: flex;
    align-items: center;
    justify-content: flex-start;

    position: absolute;
    inset: 0;
    width: 100%;
    height: 100%;
    z-index: -1;

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      object-position: 0 50%;
    }
  }
}

@keyframes rotation {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(359deg);
  }
}
