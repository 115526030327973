@import "../../../../styles/_variables.scss";

.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 88px;

  width: 100%;
  max-width: $containerWidth;
  padding: 60px $containerPadding 120px;
  margin: 0 auto;

  a {
    text-decoration: none;
  }

  .title {
    color: #0d152e;
    font-size: 40px;
    font-weight: 700;
    line-height: 60px;
    letter-spacing: -1.5px;
    width: 100%;
  }

  .posts {
    display: flex;
    align-items: center;
    gap: 32px;
    flex-wrap: wrap;
  }

  .card {
    display: flex;
    flex-direction: column;
    gap: 16px;

    width: 100%;
    max-width: 350px;
    margin: 0 auto;
    min-height: 370px;

    padding: 26px 24px 32px;
    border-radius: 10px;
    border: 1px solid #e5e5e5;
    background: var(--basic-white, #fff);
    box-shadow: 0px 22px 44px 0px rgba(149, 149, 149, 0.07);

    &:hover {
      transition-duration: 150ms;
      box-shadow: 0 5px 20px 5px #a9a6a644;
    }
    
    img {
      border-radius: 8px;
      width: 100%;
      aspect-ratio: 3 / 2;
      object-fit: cover;
    }

    .content {
      display: flex;
      flex-direction: column;
      gap: 8px;
      flex: 1;
    }

    .subtitle {
      color: #0d152e;
      font-size: 20px;
      font-weight: 700;
      line-height: 30px;
      letter-spacing: -0.5px;

      // crop after 2 lines;
      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
      overflow: hidden;
    }

    .category {
      margin-top: 5px;
      width: fit-content;
      padding: 5px 20px;
      border-radius: 20px;
      color: var(--basic-white);
      font-size: 12px;
      background-color: var(--primary-color-main);
    }

    .row {
      display: flex;
      align-items: flex-end;
      gap: 24px;
      flex: 1;
    }

    .wrapper {
      display: flex;
      align-items: center;
      gap: 4px;
    }

    .info {
      color: var(--text-color-4);
      font-size: 14px;
      font-weight: 700;
      letter-spacing: -0.5px;
    }
  }
}
