@import "../../styles/_variables.scss";

.container {
  width: 100%;
  max-width: $containerWidth;
  padding: 72px $containerPadding 120px;

  margin: 0 auto;
  text-align: center;

  .title {
    color: var(--title-color);
    font-size: 32px;
    font-weight: 700;
    line-height: 60px;
    letter-spacing: -1.5px;
    margin-bottom: 80px;
  }

  .content {
    display: flex;
    flex-wrap: wrap;
    gap: 48px;
    justify-content: center;

    .icon {
      display: flex;
      align-items: center;
      justify-content: center;

      width: 118px;
      height: 118px;
      border-radius: 10px;
      box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.1);
      background-color: var(--primary-color-main);
      transition: all 0.3s ease;
    }

    .item {
      display: flex;
      flex-direction: column;
      align-items: center;
      gap: 24px;
      flex: 0 0 220px;

      &:hover {
        .icon {
          background-color: var(--primary-color-light);
          transform: translate(0, -4px);
          box-shadow: 0 0 20px 4px rgba(0, 0, 0, 0.1);
        }
      }
    }
  }
}
