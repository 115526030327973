@import "../../styles/variables.scss";

.background {
  display: flex;
  background: #333;
  min-height: 317px;
  height: 100%;
  position: relative;
  overflow: hidden;
  z-index: 0;
}

.image {
  position: absolute;
  top: 0;
  right: 0;
  width: 100%;
  height: 100%;
  z-index: -1;
  object-fit: cover;
}

.container {
  width: 100%;
  max-width: $containerWidth;
  padding: $containerPadding;
  margin: 0 auto;

  display: flex;
  align-items: center;
  justify-content: flex-end;
  flex: 1;
  flex-wrap: wrap;

  .title {
    color: var(--basic-white, #fff);
    font-size: 32px;
    font-weight: 700;
    line-height: 40px;
    letter-spacing: -1.5px;
    max-width: 500px;
    width: 100%;
  }

  a {
    cursor: pointer;
    text-decoration: none;
    margin-left: 48px;
  }
}
