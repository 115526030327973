@import "../../styles/_variables.scss";

.container {
  display: flex;
  flex-direction: column;
  gap: 80px;

  padding: 96px $containerPadding;
  max-width: $containerWidth;
  margin: 0 auto;

  * {
    margin: 0;
    border: 0;
    background-color: transparent;
  }

  img,
  button {
    flex-shrink: 0;
  }

  button {
    cursor: pointer;
  }

  .title {
    color: #0d152e;
    font-size: 40px;
    font-weight: 700;
    line-height: 60px;
    letter-spacing: -1.5px;
  }

  .items {
    display: flex;
    min-height: 360px;
  }

  .carousel {
    display: flex;
    overflow: hidden;
    scroll-behavior: smooth;
  }

  .item {
    display: flex;
    align-items: center;
    gap: 4px;
    width: 100%;
    flex-shrink: 0;

    .image {
      display: flex;
      align-items: center;
      justify-content: center;

      img {
        object-fit: contain;
        flex-shrink: 0;
        flex: 1;
        width: 100%;
        height: 100%;
      }
    }

    .wrapper {
      display: flex;
      flex-direction: column;
      gap: 34px;
      flex: 1;

      letter-spacing: -0.5px;

      .project {
        color: #162647;
        font-size: 20px;
        font-weight: 700;
      }

      .text {
        font-size: 16px;

        color: var(--text-color-4);
        font-weight: 400;
      }
    }
  }

  .arrow {
    &:disabled {
      opacity: 0.1;
      cursor: not-allowed;
    }
  }

  .button {
    display: flex;
    align-self: center;
  }
}

@media screen and (max-width: 768px) {
  .item {
    flex-direction: column;
    gap: 24px;
  }
}
