@import "../../../../styles/_variables.scss";

.background {
  display: flex;
  align-items: center;

  position: relative;

  height: 100%;
  min-height: 600px;

  color: var(--basic-white, #fff);
  background-color: var(--basic-black);
  z-index: 0;

  .container {
    width: 100%;
    max-width: $containerWidth;
    padding: 80px $containerPadding;
    margin: 0 auto;
    text-align: center;
  }

  a {
    display: block;
    width: fit-content;
    margin: 0 auto;
    text-decoration: none;
  }

  .title {
    text-transform: uppercase;
    color: var(--basic-white, #fff);
    font-size: 18px;
    font-weight: 500;
    line-height: 30px;
    margin-bottom: 12px;
  }

  .description {
    color: var(--basic-white, #fff);
    font-size: 44px;
    font-weight: 700;
    line-height: 60px;
    letter-spacing: -1.5px;
    margin-bottom: 48px;
  }

  .image {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center;
    z-index: -1;
  }
}
