@import "../../styles/_variables.scss";

.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 64px;
  flex-wrap: wrap;

  width: 100%;
  max-width: $containerWidth;
  padding: 60px $containerPadding;
  margin: 0 auto;

  position: relative;
}

.header {
  background-color: var(--text-color-1);

  .title {
    font-size: 40px;
    font-weight: 700;
  }
}

.content {
  display: flex;
  min-height: 700px;
  height: 100%;

  h2 {
    font-size: 24px;
    font-weight: 700;
    margin-bottom: 32px;
    text-align: center;
  }

  h3 {
    font-size: 18px;
    font-weight: 700;
    margin-bottom: 32px;
  }

  section:not(:last-child) {
    margin-bottom: 32px;
  }

  h4 {
    font-size: 16px;
    font-weight: 700;
    margin-bottom: 24px;
  }

  p {
    font-size: 16px;
    font-weight: 400;
    line-height: 1.5;
    text-align: justify;
  }

  p:not(:last-child) {
    margin-bottom: 16px;
  }

  li {
    list-style-type: "- ";
  }
}
