.container {
  display: flex;
  justify-content: center;
  align-items: center;

  border: none;
  border-radius: 33px;
  padding: 18px 32px;
  font-family: "DM Sans", sans-serif;
  font-weight: 700;
  font-size: 16px;
  line-height: 21px;
  letter-spacing: -0.5px;

  color: var(--basic-white);

  transition: background 0.2s ease, color 0.2s ease;
  cursor: pointer;

  svg {
    margin-left: 5px;
  }

  &.ghost {
    background-color: transparent;
    border: 1px solid #d7297c;
    color: #d7297c;

    &:hover {
      background-color: #d7297c;
      color: #fff;
    }
  }

  &.primary {
    background: linear-gradient(
      125.29deg,
      #ce0c9a -30.75%,
      #d82e78 54.15%,
      #e35353 162.55%,
      #eb6d38 263.43%,
      #f07d28 352.98%,
      #f28322 421.95%
    );

    &:hover {
      background: radial-gradient(
        149.38% 245.01% at 1.46% 106.86%,
        #ec6e37 0%,
        #d7297c 98.28%
      );
    }
  }

  &.secondary {
    background-color: #416ff4;

    &:hover {
      background-color: #254194;
    }
  }

  &:disabled {
    background: #e8ecee;
    color: #78909c;

    &:hover {
      cursor: not-allowed;
      background: #e8ecee;
      color: #78909c;
    }
  }
}
