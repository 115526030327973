@import "../../styles/_variables.scss";

.container {
  width: 100%;
  max-width: $containerWidth;
  padding: 72px $containerPadding 0;

  margin: 0 auto;

  .title {
    text-align: center;
    color: #0d152e;
    font-size: 32px;
    font-weight: 700;
    line-height: 60px;
    letter-spacing: -1.5px;
    margin-bottom: 40px;
  }

  .action {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    gap: 16px;

    .arrow {
      &:first-child {
        transform: rotate(180deg);
      }
      &:disabled {
        opacity: 0.5;
      }
    }
  }
}

.carousel {
  display: flex;
  gap: 16px;
  scroll-behavior: smooth;
  overflow: hidden;
  padding: 72px $containerPadding;
}

.card {
  border-radius: 20px;
  background-color: var(--basic-white);
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.1);
  padding: 40px;
  flex: 0 0 min(100%, 400px);

  color: var(--basic-black);
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;

  transition: all 0.2s ease-in-out;

  &:hover {
    box-shadow: 0 4px 20px rgba(0, 0, 0, 0.2);
    transform: translateY(-4px);
    color: var(--primary-color-dark);
  }
}
