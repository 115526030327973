@import "../../../../styles/_variables.scss";

.container {
  max-width: $containerWidth;
  width: 100%;
  margin: 0 auto;
  padding: 48px $containerPadding 120px;

  .wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;

    .title {
      color: #0d152e;
      font-size: 36px;
      font-weight: 700;
      line-height: 60px;
      letter-spacing: -1.5px;
      margin-bottom: 40px;
    }

    .description {
      color: #81838c;
      font-size: 20px;
      font-weight: 400;
      line-height: 35px;
      margin-bottom: 24px;
    }

    .text {
      color: #0d152e;
      font-size: 20px;
      font-weight: 700;
      line-height: 35px;
      margin-bottom: 40px;
    }
  }

  .items {
    display: flex;
    gap: 48px;
    justify-content: space-between;
    flex-wrap: wrap;

    .item {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      gap: 16px;

      width: 100%;
      max-width: 254px;
      margin: 0 auto;

      .itemTitle {
        color: #162647;
        text-align: center;
        font-size: 20px;
        font-weight: 700;
        letter-spacing: -0.5px;
      }
    }
  }
}
