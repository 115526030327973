.container {
  display: flex;
  justify-content: center;
  gap: 20px;
  flex-wrap: wrap;

  padding: 69px 32px;
  background-color: #d31d89;

  .content {
    display: flex;
    flex-direction: column;

    color: var(--basic-white, #fff);
    width: 100%;
    max-width: 272px;

    .number {
      font-size: 56px;
      font-weight: 700;
      line-height: 60px;
      letter-spacing: -1.5px;
    }

    .text {
      font-size: 20px;
      font-weight: 500;
      letter-spacing: -0.5px;
    }
  }
}
